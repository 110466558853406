@font-face {
  font-family: 'Playfair Display';
  src: url('../fonts/playfair/PlayfairDisplay-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Playfair Display';
  src: url('../fonts/playfair/PlayfairDisplay-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Playfair Display';
  src: url('../fonts/playfair/PlayfairDisplay-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Playfair Display';
  src: url('../fonts/playfair/PlayfairDisplay-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Playfair Display';
  src: url('../fonts/playfair/PlayfairDisplay-ExtraBold.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Cabin';
  src: url('../fonts/cabin/Cabin-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Cabin';
  src: url('../fonts/cabin/Cabin-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Cabin';
  src: url('../fonts/cabin/Cabin-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Cabin';
  src: url('../fonts/cabin/Cabin-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
